import React from 'react'
import dishwashing from "../assets/products/dishwashing-paste.jpg"
import bleach from "../assets/products/bleach.jpg"
import toiletCleaner from "../assets/products/toilet-cleaner2.jpg"
import handwash from "../assets/products/handwash2.jpg"
import carpetCleaner from "../assets/products/carpet-cleaner.jpg"
import multipurpose from "../assets/products/multipurpose.jpg"
import handwash2 from "../assets/products/handwash3.jpg"

import multipurpose3 from "../assets/products/multipurpose20L.jpg"
import sanitizers2 from "../assets/products/sanitizer20L.jpg"
import handwash3 from "../assets/products/handwash20L.jpg"
import bleach2 from "../assets/products/bleach-20L.jpg"
import multipurpose4 from "../assets/products/multipurpose20L-2.jpg"
import carpetCleaner2 from "../assets/products/carpert-cleaner20L.jpg"
import toiletCleaner2 from '../assets/products/toilet20L_1.jpg'
const ProductsBody = () => {
  return (
    <div className='products-body-section'>
           <div className="inner-row">
                    <div className="products-body-section-content">
                             <div className="products-body-intro">
                                      <h1>Expanding product list</h1>
                                      <p>We manufacture a wide range of quality  hygiene products ranging from Home care, personal care to industrial cleaning products.</p>
                             </div>

                             <div className="products-list">
                                     <div className="products-category">
                                            <h3>Home Care</h3>

                                            <div className="category-row">
                                                    <div className="single-product">
                                                              <img src={dishwashing} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Zelia Dishwashing Paste</h2>   
                                                               </div>
                                                    </div>
                                                    <div className="single-product">
                                                              <img src={bleach} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Bleach</h2>   
                                                               </div>
                                                    </div>
                                                    <div className="single-product">
                                                              <img src={toiletCleaner} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Toilet Cleaner</h2>   
                                                               </div>
                                                    </div>
                                                    <div className="single-product">
                                                              <img src={handwash} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Handwashing Liquid</h2>   
                                                               </div>
                                                    </div>
                                                    <div className="single-product">
                                                              <img src={carpetCleaner} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Carpet Cleaner</h2>   
                                                               </div>
                                                    </div>
                                                    <div className="single-product">
                                                              <img src={multipurpose} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Multipurpose Liquid</h2>   
                                                               </div>
                                                    </div>
                                            </div>
                                     </div>

                                     <div className="products-category">
                                             <h3>Personal Care</h3>

                                             <div className="category-row">
                                                      <div className="single-product">
                                                              <img src={handwash2} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Handwashing Liquid</h2>   
                                                               </div>
                                                      </div>
                                             </div>
                                     </div>

                                     <div className="products-category">
                                             <h3>Institutions / Industrial</h3>

                                             <div className="category-row">
                                                      <div className="single-product">
                                                              <img src={multipurpose3} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Multipurpose Surface Cleaner</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={handwash3} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Handwashing Liquid</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={sanitizers2} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Sanitizer</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={toiletCleaner2} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Toilet Cleaner</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={bleach2} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Bleach</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={carpetCleaner2} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Carpet Cleaner</h2>   
                                                               </div>
                                                      </div>
                                                      <div className="single-product">
                                                              <img src={multipurpose4} alt="" />
                                                              <div className="simple-product-text">
                                                                      <h2>Multipurpose Liquid</h2>   
                                                               </div>
                                                      </div>
                                             </div>
                                     </div>
                             </div>
                    </div>
           </div>
    </div>
  )
}

export default ProductsBody