import React, { useState, useEffect} from 'react'
import "../css/products.css"
import Preloader from '../components/Preloader'
import Navigation from '../components/Navigation/Navigation'
import ProductsBody from '../components/ProductsBody'
import Nudge from "../components/Nudge"
import Footer from "../components/Footer"
const Products = () => {
    const [loading, setLoading] = useState(true)
    const timeBefore = new Date().getTime()
   
    useEffect(() =>{
      const timeAfter = new Date().getTime()
      const loadingTime = timeAfter - timeBefore
    
      setTimeout(() =>{
            setLoading(false)
      }, loadingTime + 2000)
    }, [timeBefore])

  return (
    <>
        { loading ? 
            <Preloader load={loading} />
             :
            <>
                 <div className="hero-intro products">
                          <Navigation />
                          <div className="hero-content">
                                   <div className="inner-row">
                                            <h1>McDave Products</h1>
                                   </div>
                          </div>
                  </div>
                  <ProductsBody />
                  <Nudge />
                  <Footer />
            </>
       }
 </>
  )
}

export default Products