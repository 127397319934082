import React from 'react'
import client1 from "../assets/partners/unilever.png"
import client2 from "../assets/partners/honda.png"
import client3 from "../assets/partners/finsbury.png"
import client4 from "../assets/partners/zenith.png"
import client5 from "../assets/partners/lintons.png"
import client6 from '../assets/partners/mrgreen.png'
import client7 from "../assets/partners/kenmeat.png"
import client8 from "../assets/partners/bb.png"
import client9 from "../assets/partners/azaavi.png"
import client10 from "../assets/partners/kam.png"
import client11 from "../assets/partners/owit.png"
import Testimonials from './Testimonials'
const Partners = () => {
  return (
    <div className='partners-section'>
            <div className="inner-row">
                    <div className="partners-section-content">
                             <h1>Clients we have worked with</h1>

                             <div className="clientele-section-row">
                                     <div className="client-moja">
                                           <img src={client1} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client2} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client3} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client4} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client5} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client6} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client7} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client8} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client9} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client10} alt="" />
                                     </div>
                                     <div className="client-moja">
                                           <img src={client11} alt="" />
                                     </div>
                             </div>

                             <Testimonials />
                    </div>
            </div>
    </div>
  )
}

export default Partners