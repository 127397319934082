import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import productImg1 from "../assets/products/toilet-cleaner3.jpg"
import productImg2 from "../assets/products/liquid-detergent2.jpg"
import productImg3 from "../assets/products/bleach.jpg"
import productImg4 from "../assets/products/carpet-cleaner2.jpg"
import productImg5 from "../assets/products/handwash2.jpg"
import productImg6 from "../assets/products/sanitizer.jpg"
import productImg7 from "../assets/products/dishwashing-paste.jpg"
import "swiper/css"
import "swiper/css/navigation"

import { Navigation } from "swiper"
const HomeProducts = () => {
  return (
    <div className='home-products-section'>
                <div className="products-section-content">
                               <div className="products-section-intro">
                                      <h1>Products</h1>
                                      <p>We manufacture a wide range of quality  hygiene products ranging from home care, personal care to industrial cleaning products.</p>
                               </div>

                               <div className="products-section-row">
                                       <Swiper
                                               slidesPerView={1}
                                               spaceBetween={30}
                                               navigation={true}
                                               modules={[Navigation]}
                                               breakpoints={{
                                                1090:{
                                                     slidesPerView: 3
                                                },
                                                768:{
                                                     slidesPerView: 1
                                                },
                                             }}
                                       >
                                              <SwiperSlide>
                                                     <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg1} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Toilet Cleaner</h2>
                                                              </div>
                                                       </div>  
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                     <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg2} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Multipurpose Liquid Detergent</h2>
                                                              </div>
                                                       </div>    
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                       <div className="product-moja">
                                                               <div className="image-section">
                                                                         <img src={productImg3} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Bleach</h2>
                                                              </div>
                                                         </div>  
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                        <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg4} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Carpet Cleaner</h2>
                                                              </div>
                                                          </div>  
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                         <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg5} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Handwash</h2>
                                                              </div>
                                                          </div>  
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                    <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg6} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Sanitizer</h2>
                                                              </div>
                                                          </div>    
                                              </SwiperSlide> 
                                              <SwiperSlide>
                                                    <div className="product-moja">
                                                              <div className="image-section">
                                                                         <img src={productImg7} alt="" />
                                                              </div>
                                                              <div className="image-details">
                                                                      <h2>Dishwashing Paste</h2>
                                                              </div>
                                                          </div>    
                                              </SwiperSlide> 
                                       </Swiper>
                               </div>
                       </div>
    </div>
  )
}

export default HomeProducts