import React from 'react'
import contract from "../assets/contract-manufacturing2.jpg"
import repacking from "../assets/repacking.jpg"
import datecoding from "../assets/date-coding.jpg"
import banding from "../assets/p-banding.jpg"
import sleeving from "../assets/chemical-blending.jpg"
// import { RxArrowRight } from "react-icons/rx"
// import { NavLink } from 'react-router-dom'
import cert1 from "../assets/cert1.png"
import cert2 from "../assets/cert2.png"
import cert4 from "../assets/cert4.png"
import cert5 from "../assets/cert3.png"
import cert6 from "../assets/cert5.png"

import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/css"
import "swiper/css/navigation"

const Capabilities = () => {
  return (
    <div className='capability-section'>
            <div className="inner-row">
                    <div className="capability-section-content">
                             <div className="certification-slider">
                                     <Swiper 
                                          spaceBetween={50}
                                          slidesPerView={2}
                                          initialSlide={0}
                                          loop={true}
                                          autoplay={{
                                                  delay: 2500,
                                                  disableOnInteraction: false
                                          }}
                                          modules={[Autoplay]}
                                         breakpoints={{
                                                1090: {
                                                    slidesPerView: 4
                                                },
                                               
                                                600: {
                                                        slidesPerView: 3
                                                }
                                         }}
                                     >
                                              <SwiperSlide>
                                                       <div className="swiper-slide">
                                                               <div className="logo-moja">
                                                                    <img src={cert1} alt="" />
                                                               </div>
                                                       </div>
                                              </SwiperSlide>
                                              <SwiperSlide>
                                                      <div className="swiper-slide">
                                                               <div className="logo-moja">
                                                                     <img src={cert2} alt="" />
                                                               </div>
                                                      </div>
                                              </SwiperSlide>
                                              <SwiperSlide>
                                                        <div className="swiper-slide">
                                                                <div className="logo-moja">
                                                                       <img src={cert4} alt="" />
                                                                </div>
                                                        </div>
                                              </SwiperSlide>
                                              <SwiperSlide>
                                                         <div className="swiper-slide">
                                                                 <div className="logo-moja short">
                                                                        <img src={cert5} alt="" />
                                                                 </div>
                                                         </div>
                                              </SwiperSlide>
                                              <SwiperSlide>
                                                        <div className="swiper-slide">
                                                                 <div className="logo-moja short">
                                                                       <img src={cert6} alt="" />
                                                                 </div>
                                                        </div>
                                              </SwiperSlide>
                                     </Swiper>
                             </div>
                             <div className="capability-intro">
                                     <h3>Our Services</h3>       
                             </div>
                             <div className="capability-section-row">
                                     <div className="capability-section-column">
                                              <div className="small-bubble normal"></div>
                                               <div className="capability-description-side">
                                                        <div className="capability-description-inner">
                                                                  <h2>Manufacturing</h2>
                                                                  <p>Mcdave holdings limited offers contract manufacturing services  for a wide range of hygiene products to clients worldwide. We offer advanced custom and stock scientific formulations by incorporating state-of-the-art technology.</p>

                                                                  {/* <NavLink to={'/service/contract-manufacturing/'}>
                                                                       <span>
                                                                            <RxArrowRight  className='icon'/>
                                                                       </span>
                                                                      <span>Learn more</span></NavLink> */}
                                                        </div>
                                               </div>
                                               <div className="capability-image-side">
                                                      <img src={contract} alt="" />
                                               </div>
                                     </div>
                                     <div className="capability-section-column">
                                               <div className="small-bubble shift"></div>
                                               <div className="capability-image-side">
                                                      {/* <img src={repacking} alt="" /> */}
                                               </div>
                                               <div className="capability-description-side">
                                                        <div className="capability-description-inner">
                                                                  <h2>Packing & Repacking</h2>

                                                                  <p>We can pack or repack your product either in powder or liquid form in various forms of packaging that vary from: Big bags, Small bags , Drums , Cans , IBC tanks, Euro pallets , One way pallets , Sacks , Boxes to Crates.</p>

                                                                  {/* <NavLink to={'/service/packing-and-repacking/'}>
                                                                       <span>
                                                                            <RxArrowRight  className='icon'/>
                                                                       </span>
                                                                      <span>Learn more</span></NavLink> */}
                                                        </div>
                                               </div>         
                                     </div>
                                     <div className="capability-section-column">
                                         <div className="small-bubble normal tweak"></div>
                                               <div className="capability-description-side">
                                                        <div className="capability-description-inner">
                                                                  <h2>Date Coding</h2>
                                                                  <p>We date and code each of your individual retail products as we package them in bulk display cartons and then in master shipping cases. We clearly print date codes, batch codes, production codes, and product identification as needed. </p>

                                                                  {/* <NavLink to={'/service/date-coding/'}>
                                                                       <span>
                                                                            <RxArrowRight  className='icon' />
                                                                       </span>
                                                                      <span>Learn more</span></NavLink> */}
                                                        </div>
                                               </div>
                                               <div className="capability-image-side">
                                                      <img src={datecoding} alt="" />
                                               </div>
                                     </div>
                                     <div className="capability-section-column">
                                               <div className="small-bubble shift"></div>
                                               <div className="capability-image-side">
                                                      <img src={banding} alt="" />
                                               </div>
                                               <div className="capability-description-side">
                                                        <div className="capability-description-inner">
                                                                  <h2>Promotional Banding</h2>

                                                                  <p>Are you doing product promotions, special offers such as 3 for 2 campaigns, or cross selling two products bundled together at a reduced price or other campaign packaging? Our state-of-the-art bundling machines will bundle your products simply, securely, attractively and in a customer-friendly way.</p>

                                                                  {/* <NavLink to={'/service/promotional-banding/'}>
                                                                       <span>
                                                                            <RxArrowRight  className='icon'/>
                                                                       </span>
                                                                      <span>Learn more</span></NavLink> */}
                                                        </div>
                                               </div>         
                                     </div>

                                     <div className="capability-section-column">
                                         <div className="small-bubble normal tweak"></div>
                                               <div className="capability-description-side">
                                                        <div className="capability-description-inner">
                                                                  <h2>Chemical Blending</h2>
                                                                  <p>We have experience in providing custom chemical blending for powder and liquid solutions, tailored to support the needs of our customers.  We have invested in ample process equipment and associated infrastructure to ensure the quality and flexibility that you care for.</p>

                                                                  {/* <NavLink to={'/service/sleeving/'}>
                                                                       <span>
                                                                            <RxArrowRight  className='icon'/>
                                                                       </span>
                                                                      <span>Learn more</span></NavLink> */}
                                                        </div>
                                               </div>
                                               <div className="capability-image-side">
                                                      <img src={sleeving} alt="" />
                                               </div>
                                     </div>
                             </div>
                    </div>
            </div>
    </div>
  )
}

export default Capabilities