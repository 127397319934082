import React from 'react'
import image1 from "../assets/choose1.jpg"
import image2 from "../assets/choose2.jpg"
import image3 from "../assets/choose3.jpg"
import image4 from "../assets/choose4.jpg"

const Choose = () => {
  return (
    <div className='choose-section'>
            <div className="inner-row">
                    <div className="choose-section-content">
                             <div className="choose-intro">
                                     <h3>Why Choose Us</h3>
                                     <h1>We seek to establish a long term relationship with our customers.</h1>
                             </div>

                             <div className="choose-section-row">
                                     <div className="images-side-column">
                                                <div className="image-moja">
                                                         <img src={image1} alt="" />
                                                </div>
                                                <div className="image-moja">
                                                         <img src={image2} alt="" />
                                                </div>
                                                <div className="image-moja">
                                                         <img src={image3} alt="" />
                                                </div>
                                                <div className="image-moja">
                                                         {/* <img src={image4} alt="" /> */}
                                                </div>
                                                <div className="tight-circle"></div>
                                                
                                     </div>
                                     <div className="choose-description-column">
                                     <div className="description-side-column">
                                            <div className="choose-moja">
                                                      <span>1</span>
                                                      <p>We apply good manufacturing practices (GMP), total productive maintenance (TPM) and quality management systems (QMS ISO 9001:2015).</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>2</span>
                                                      <p>Careful selection of raw materials from key manufacturers worldwide.</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>3</span>
                                                      <p>Safe and Creative formula development.</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>4</span>
                                                      <p>Reliable in meeting client requirements.</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>5</span>
                                                      <p>High level of customer satisfaction.</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>6</span>
                                                      <p>We can create a complete product range for your brand.</p>
                                             </div>
                                             <div className="choose-moja">
                                                      <span>7</span>
                                                      <p>Care and listening to the customer.</p>
                                             </div>
                                     </div>
                                     </div>
                             </div>
                    </div>
            </div>
    </div>
  )
}

export default Choose