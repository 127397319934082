export const capabilities = [
    {
        'id': 1,
        'title': 'Contract Manufacturing',
        'url_param': 'contract-manufacturing',
        'hero_image': '../../img/contract-manufucaturingbg.jpg',
        'text_main': 'We are Masters in Manufacturing',
        'text_main_description': 'Mcdave holdings limited offers contract manufacturing services  for a wide range of hygiene products to clients worldwide. We offer advanced scientific formulations, custom and stock, incorporating state-of-the-art technology. ',
        'simple_title': 'Our Services include: ',
        'service_types': ['Bulk Supply', 'Finished product with primary packaging and components supplied by the customer', 'Finished product full service including artwork and packaging.'],
        'examples': [
            {
                'id': 0,
                'name': 'Scouring Powder',
                'image': '../../img/contract1.jpg'
            },
            {
                'id': 1,
                'name': 'Toilet Cleaner',
                'image': '../../img/contract3.jpg'
            },
            {
                 'id': 2,
                 'name': 'Dish Washing Liquid',
                 'image': '../../img/contract2.jpg'
            },
            {
                'id': 3,
                'name': 'Dish Washing Liquid',
                'image': '../../img/contract4.jpg'
           },
           {
            'id': 4,
            'name': 'Dish Washing Liquid',
            'image': '../../img/contract5.jpg'
       },
       {
        'id': 5,
        'name': 'Dish Washing Liquid',
        'image': '../../img/contract6.jpg'
        },
        {
            'id': 6,
            'name': 'Dish Washing Liquid',
            'image': '../../img/contract7.jpg'
       },
        ]
    },
    {
        'id': 2,
        'title': 'Packing & Repacking',
        'url_param': 'packing-and-repacking',
        'hero_image': '../../img/packingbg.jpg',
        'text_main': 'Complete Repackaging',
        'text_main_description': "We can pack or repack your product either in powder or liquid form in various forms of packaging that vary from: Big bags, Small bags , Drums , Cans , IBC tanks, Euro pallets , One way pallets , Sacks , Boxes to Crates.",
        'simple_title': 'Below are the packaging options available:',
        'service_options': [
            {
                 'id': 0,
                 'option_title': 'Liquid Packaging Options from Large to Small',
                 'option_description': 'Whether you have bulk needs or smaller, individually packaged products to fill, we have a wide range of liquid packaging solutions - including bottles, pails, drums, totes, and bulk tank trucks.'
            },
            {
                'id': 1,
                'option_title': 'Industry-Leading Powder packing capabilities',
                'option_description': 'Mcdave Limited has some of the most extensive powder packaging and repackaging capabilities in the industry. We handle bottles and sachets in many sizes and configurations, bags, boxes, pails and super sacks.'
            },
            {
                'id': 2,
                'option_title': 'The Volume you need, wherever you need it',
                'option_description': 'Our chemical packaging capacities range from 500gm to 1 tonne. We have bottle capabilities at our factory that can efficiently fill 2000 pieces of 1kg and 500gms bottles each, as well as powder pack lines buckets at our, facility.'
            }
        ],
        'examples': [
            {
                'id': 0,
                'name': 'Scouring Powder',
                'image': '../../img/packing1.jpg'
            },
            {
                'id': 1,
                'name': 'Toilet Cleaner',
                'image': '../../img/packing2.jpg'
            },
            {
                 'id': 2,
                 'name': 'Dish Washing Liquid',
                 'image': '../../img/packing3.jpg'
            },
            {
                'id': 3,
                'name': 'Dish Washing Liquid',
                'image': '../../img/packing4.jpg'
           },
           {
            'id': 4,
            'name': 'Dish Washing Liquid',
            'image': '../../img/packing5.jpg'
       },
       {
        'id': 5,
        'name': 'Dish Washing Liquid',
        'image': '../../img/packing6.jpg'
   },
        ]
    },
    {
        'id': 3,
        'title': 'Date Coding',
        'url_param': 'date-coding',
        'hero_image': '../../img/date-coding.jpg',
        'text_main': 'Patching Dates to Products',
        'text_main_description': 'We will date and code each of your individual retail products as we package them. Once in bulk display cartons and then in master shipping cases, we will clearly print date codes, batch codes, production codes, and product identification as needed.',
        // 'simple_title': 'Below are some of the McDave products we make: ',
        'examples': [
            {
                'id': 0,
                'name': 'Scouring Powder',
                'image': '../../img/date-coding1.jpg'
            },
            {
                'id': 1,
                'name': 'Toilet Cleaner',
                'image': '../../img/date-coding2.jpg'
            },
            {
                 'id': 2,
                 'name': 'Dish Washing Liquid',
                 'image': '../../img/date-coding3.jpg'
            },

        ]
    },
    {
        'id': 4,
        'title': 'Promotional Banding',
        'url_param': 'promotional-banding',
        'hero_image': '../../img/promotion-bandingbg.jpg',
        'text_main': 'Product Banding',
        'text_main_description': 'Are you doing product promotions, special offers such as 3 for 2 campaigns, or cross selling two products bundled together at a reduced price or other campaign packaging? Our state-of-the-art bundling machines will bundle your products simply, securely, attractively and in a customer-friendly way.',
        'simple_title': '',
        'examples': [
            {
                'id': 0,
                'name': 'Scouring Powder',
                'image': '../../img/p-banding.jpg'
            },
            {
                'id': 1,
                'name': 'Toilet Cleaner',
                'image': '../../img/p-banding1.jpg'
            },
            // {
            //      'id': 2,
            //      'name': 'Dish Washing Liquid',
            //      'image': '../../img/date-coding3.jpg'
            // },

           
        ]
    },
    {
        'id': 5,
        'title': 'Chemical Blending',
        'url_param': 'chemical-blending',
        'hero_image': '../../img/chemical-blendingbg.jpg',
        'text_main': 'Dedicated to Quality',
        'text_main_description': 'We have experience in providing custom chemical blending for powder and liquid solutions tailored to support the needs of our customers.  We have invested in ample process equipment and associated infrastructure to ensure the quality and flexibility that you care for.',
        // 'simple_title': 'Below are some of the McDave products we make: ',

        'examples': [
            {
                'id': 0,
                'name': 'Scouring Powder',
                'image': '../../img/chemical-blending.jpg'
            },
            {
                'id': 1,
                'name': 'Toilet Cleaner',
                'image': '../../img/chemical-blending2.jpg'
            },
            {
                 'id': 2,
                 'name': 'Dish Washing Liquid',
                 'image': '../../img/chemical-blending3.jpg'
            },
           
        ]
    }
]