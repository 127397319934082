import React, { useState} from 'react'
import { MdMapsHomeWork } from "react-icons/md"
import { BsEnvelope } from "react-icons/bs"
import { FiPhoneCall } from "react-icons/fi"
import axios from 'axios'
import { useForm } from 'react-hook-form'
const ContactBody = () => {
       const API_PATH = 'https://mcdave.co.ke/mail/server.php'

       const [btnMsg, setBtnMsg] = useState("Send Message")
       const [serverMsg, setServerMsg] = useState("")
       const { register, handleSubmit , formState: { errors }, reset} = useForm()
       
       const submitForm = (form_data) =>{
             setBtnMsg("Submitting...")
             axios.post(API_PATH, { form_data})
             .then(res => {
                   setServerMsg(res.data.message)
                   console.log(res.data.message)
             })
             .finally(()=>{
                  setBtnMsg("Submitted")
             })
       
             reset()
       }
       setTimeout(()=>{
            setBtnMsg("Submit Message")
            setServerMsg("");
       }, 10000)

  return (
    <div className='contactbody-section'>
           <div className="inner-row">
                  <div className="contact-body-content">
                          <div className="quick-details-row">
                                   <div className="column">
                                            <div className="icon-section">
                                                     <span><MdMapsHomeWork /></span>
                                            </div>
                                            <div className="column-text">
                                                    <p>63 Enterprise road, Industrial Area, Unit No.1</p>
                                            </div>
                                   </div>
                                   <div className="column">
                                            <div className="icon-section">
                                                    <span><BsEnvelope /></span>
                                            </div>
                                            <div className="column-text">
                                                   <p>production@mcdave.co.ke</p>
                                                    <p>support@mcdave.co.ke</p>
                                            </div>
                                   </div>
                                   <div className="column">
                                           <div className="icon-section">
                                                  <span><FiPhoneCall /></span>
                                           </div>
                                           <div className="column-text">
                                                    <p>+254 728319893</p>
                                                    <p>+254 20 7859680</p>
                                           </div>
                                   </div>
                          </div>

                        
                          <div className="form-section">
                                 <div className="form-intro">
                                        <h1>Ready to get Started?</h1>
                                        <p>If you have a manufacturing inquiry, a general inquiry or you’re looking for a job you’ve come to the right place. Complete the form below, and somebody will get back to you right away.</p>
                                 </div>

                                 <div className="form-body">
                                        <form action="#" onSubmit={handleSubmit(submitForm)}>
                                                 <div className="form-row">
                                                         <div className="form-column">
                                                                <label htmlFor="name">Full Name</label>
                                                                <input type="text" name="user_name"  className='form-control' {...register("Name", { required: "Your name is required"})}  placeholder=' ' />
                                                                <span>{errors.Name && errors.Name.message}</span>
                                                         </div>
                                                         <div className="form-column">
                                                                <label htmlFor="name">Email</label>
                                                                <input type="email" name='user_email' className='form-control' {...register("Email", { required: "Your email address is required"})} placeholder=' '   />
                                                                <span>{errors.Email && errors.Email.message}</span>
                                                         </div>
                                                 </div>
                                                 <div className="form-row">
                                                         <div className="form-column">
                                                                <label htmlFor="phone number">Phone Number</label>
                                                                <input type="number" name="user_phone" className='form-control' {...register("Phone")} placeholder=' ' 
                                                                 />
                                                         </div>
                                                         <div className="form-column">
                                                                <label htmlFor="service">Choose Service</label>
                                                                <select name='user_inquiry'  {...register('Service')}  className='form-control'>
                                                                       <option value="General Inquiry">General Inquiry</option>
                                                                       <option value="Contract Manufacturing">Contract Manufacturing</option>
                                                                       <option value="Custom Manufacturing">Custom Manufacturing</option>
                                                                       <option value="Sales">Sales</option>
                                                                </select>
                                                         </div>
                                                 </div>
                                                 <div className="form-row">
                                                         <div className="form-column">
                                                                <label htmlFor="company">Company Name</label>
                                                                <input type="text" name="user_company" className='form-control' {...register("Company")} placeholder=' ' />
                                                         </div>
                                                         <div className="form-column interest">
                                                                   <label htmlFor="Interest"></label>
                                                                   <input type="text" name="user_interest" className='form-control interest' {...register("Special")}  />
                                                         </div>
                                                 </div>
                                                 <div className="form-message">
                                                         <div className="form-column">
                                                                <label htmlFor="message">Message</label>
                                                                <textarea name="user_message" className='form-control' id="" cols="30" rows="10" {...register("Message", { required: "Please enter your message"})} placeholder=' ' ></textarea>
                                                                <span></span>
                                                                <span>{errors.Message && errors.Message.message}</span>
                                                         </div>
                                                 </div>

                                                 <div className="form-btn">
                                                         <button type="submit" className='form-btn'>{btnMsg}</button>
                                                 </div>
                                                 { serverMsg === '' ? '' : <p className='server-msg'>{serverMsg}</p>}
                                        </form>
                                 </div>
                          </div>

                          
                  </div>
           </div>
    </div>
  )
}

export default ContactBody