import React from 'react'
import { RxArrowRight } from "react-icons/rx"
import { NavLink } from 'react-router-dom'
const AboutSection = () => {
  return (
    <div className='about-section'>
           <div className="inner-row">
                  <div className="about-section-content">
                            <div className="bg-circle"></div>
                            <div className="bg-small-circle"></div>
                            <div className="about-section-content-row">
                                     <div className="about-section-left-column">
                                             <h3>Who we are</h3>
                                             <h2>Professional Contract manufacturing services</h2>
                                             <p> Mcdave Holdings Limited is an established  Manufacturer based in Nairobi Kenya. We manufacture quality hygiene products for personal care, home care and industrial use for local and international brands.</p>
                                     </div>
                                     <div className="about-section-right-column">
                                              <p>We have invested in world class liquid and powder blending equipment, packing and repacking equipment, material handling facilities, liquid and powder detergent processing plants  as well as  trained and qualified personnel.</p>
                                               <p>Contract manufacturing makes it easier for a company to produce its products without having to take on considerable overheads.</p>
                                              <NavLink to={'/about-us/'}>Explore More <span><RxArrowRight /></span></NavLink>
                                     </div>
                            </div>
                  </div>
           </div>
    </div>
  )
}

export default AboutSection